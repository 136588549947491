import { OperatorFunction, MonoTypeOperatorFunction, TruthyTypesOf } from '../types';
import { operate } from '../util/lift';
import { createOperatorSubscriber } from './OperatorSubscriber';

/** @deprecated Use a closure instead of a `thisArg`. Signatures accepting a `thisArg` will be removed in v8. */
export function filter<t, S="" extends="" T,="" A="">(predicado: (este: A, valor: T, índice: número) => valor es S, esteArg: A): OperadorFunción<t, S="">;
filtro de función de exportación<t, S="" extends="" T="">(predicado: (valor: T, índice: número) => valor es S): OperadorFunción<t, S="">;
filtro de función de exportación<t>(predicado: BooleanConstructor): OperadorFunción<t, TruthyTypesOf<T="">>;
/** @deprecated Utiliza un closure en lugar de `thisArg`. Las firmas que acepten `thisArg` serán eliminadas en la v8. */
filtro de función de exportación<t, A="">(predicado: (este: A, valor: T, índice: número) => booleano, esteArg: A): MonoTypeOperatorFunction<t>;
filtro de función de exportación<t>(predicado: (valor: T, índice: número) => booleano): MonoTypeOperatorFunction<t>;

/**
 * Filtra los elementos emitidos por el Observable fuente emitiendo sólo aquellos que
 * satisfacen un predicado especificado.
 *
 * <span class="informal">Como
 * Array.prototype.filter()](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter),
 * sólo emite un valor de la fuente si pasa una función de criterio.</span>
 *
 * ![](filter.png)
 *
 * Similar to the well-known `Array.prototype.filter` method, this operator
 * takes values from the source Observable, passes them through a `predicate`
 * function and only emits those values that yielded `true`.
 *
 * ## Example
 *
 * Emit only click events whose target was a DIV element
 *
 * ```ts
 * import { fromEvent, filter } from 'rxjs';
 *
 * const div = document.createElement('div');
 * div.style.cssText = 'width: 200px; height: 200px; background: #09c;';
 * document.body.appendChild(div);
 *
 * const clicks = fromEvent(document, 'click');
 * const clicksOnDivs = clicks.pipe(filter(ev => (<htmlelement>ev.target).tagName === 'DIV'));
 * clicksOnDivs.subscribe(x => console.log(x));
 * ```
 *
 * @see {@link distinct}
 * @see {@link distinctUntilChanged}
 * @see {@link distinctUntilKeyChanged}
 * @see {@link ignoreElements}
 * @see {@link partition}
 * @see {@link skip}
 *
 * @param predicate A function that
 * evaluates each value emitted by the source Observable. If it returns `true`,
 * the value is emitted, if `false` the value is not passed to the output
 * Observable. The `index` parameter is the number `i` for the i-th source
 * emission that has happened since the subscription, starting from the number
 * `0`.
 * @param thisArg An optional argument to determine the value of `this`
 * in the `predicate` function.
 * @return A function that returns an Observable that emits items from the
 * source Observable that satisfy the specified `predicate`.
 */
export function filter<t>(predicado: (valor: T, índice: número) => booleano, thisArg?: any): MonoTypeOperatorFunction<t> {
  return operate((source, subscriber) => {
    // An index passed to our predicate function on each call.
    let index = 0;

    // Subscribe to the source, all errors and completions are
    // forwarded to the consumer.
    source.subscribe(
      // Call the predicate with the appropriate `this` context,
      // if the predicate returns `true`, then send the value
      // to the consumer.
      createOperatorSubscriber(subscriber, (value) => predicate.call(thisArg, value, index++) && subscriber.next(value))
    );
  });
}
</t></t></htmlelement></t></t></t></t,></t,></t></t,></t,></t,></t,>