import { SchedulerLike, ValueFromArray } from '../types';
import { Observable } from '../Observable';
import { popScheduler } from '../util/args';
import { from } from './from';

// Devs are more likely to pass null or undefined than they are a scheduler
// without accompanying values. To make things easier for (naughty) devs who
// use the `strictNullChecks: false` TypeScript compiler option, these
// overloads with explicit null and undefined values are included.

export function of(value: null): Observable<null>;
export function of(valor: indefinido): Observable<undefined>;

/** @deprecated El parámetro `scheduler` será eliminado en la v8. Utilice `scheduled`. Detalles: https://rxjs.dev/deprecations/scheduler-argument */
export function of(planificador: PlanificadorComo): Observable<never>;
/** @deprecated El parámetro `scheduler` será eliminado en la v8. Utilice `scheduled`. Detalles: https://rxjs.dev/deprecations/scheduler-argument */
función de exportación de<a extends="" readonly="" unknown[]="">(...valoresAndScheduler: [...A, SchedulerLike]): Observable<valuefromarray<a>>;

función de exportación of(): Observable<never>;
/** @deprecated No especifique parámetros de tipo explícitos. Las firmas con parámetros de tipo que no se puedan deducir se eliminarán en la versión 8. */
función de exportación de<t>(): Observable<t>;
función de exportación de<t>(valor: T): Observable<t>;
función de exportación de<a extends="" readonly="" unknown[]="">(...valores: A): Observable<valuefromarray<a>>;

/**
 * Convierte los argumentos en una secuencia observable.
 *
 * <span class="informal">Cada argumento se convierte en una notificación "siguiente".</span>
 *
 * ![](of.png)
 *
 * Unlike {@link from}, it does not do any flattening and emits each argument in whole
 * as a separate `next` notification.
 *
 * ## Examples
 *
 * Emit the values `10, 20, 30`
 *
 * ```ts
 * import { of } from 'rxjs';
 *
 * of(10, 20, 30)
 *   .subscribe({
 *     next: value => console.log('next:', value),
 *     error: err => console.log('error:', err),
 *     complete: () => console.log('the end'),
 *   });
 *
 * // Outputs
 * // next: 10
 * // next: 20
 * // next: 30
 * // the end
 * ```
 *
 * Emit the array `[1, 2, 3]`
 *
 * ```ts
 * import { of } from 'rxjs';
 *
 * of([1, 2, 3])
 *   .subscribe({
 *     next: value => console.log('next:', value),
 *     error: err => console.log('error:', err),
 *     complete: () => console.log('the end'),
 *   });
 *
 * // Outputs
 * // next: [1, 2, 3]
 * // the end
 * ```
 *
 * @see {@link from}
 * @see {@link range}
 *
 * @param {...T} values A comma separated list of arguments you want to be emitted
 * @return {Observable} An Observable that emits the arguments
 * described above and then completes.
 */
export function of<t>(...args: Matriz<t |="" SchedulerLike="">): Observable<t> {
  const scheduler = popScheduler(args);
  return from(args as T[], scheduler);
}
</t></t></t></valuefromarray<a></a></t></t></t></t></never></valuefromarray<a></a></never></undefined></null>