import { concat } from '../observable/concat';
import { OperatorFunction, SchedulerLike, ValueFromArray } from '../types';
import { popScheduler } from '../util/args';
import { operate } from '../util/lift';

// Devs are more likely to pass null or undefined than they are a scheduler
// without accompanying values. To make things easier for (naughty) devs who
// use the `strictNullChecks: false` TypeScript compiler option, these
// overloads with explicit null and undefined values are included.

export function startWith<t>(valor: null): OperatorFunction<t, T="" |="" null="">;
función de exportación startWith<t>(valor: indefinido): OperadorFunción<t, T="" |="" undefined="">;

/** @deprecated El parámetro `scheduler` será eliminado en la v8. Utilice `scheduled` y `concatAll`. Detalles: https://rxjs.dev/deprecations/scheduler-argument */
exportar función startWith<t, A="" extends="" readonly="" unknown[]="T[]">(
  ...valoresYProgramador: [...A, ProgramadorComo]
): OperadorFunción<t, T="" |="" ValueFromArray<A="">>;
función de exportación startWith<t, A="" extends="" readonly="" unknown[]="T[]">(...valores: A): OperadorFunción<t, T="" |="" ValueFromArray<A="">>;

/**
 * Devuelve un observable que, en el momento de la suscripción, emitirá sincrónicamente todos los
 * valores proporcionados a este operador, luego se suscribirá a la fuente y reflejará todas sus emisiones
 * a los suscriptores.
 *
 * Esta es una forma útil de saber cuándo se ha producido la suscripción en un observable existente.
 *
 * <span class="informal">Primero emite sus argumentos en orden, y luego cualquier
 * emisiones de la fuente.</span>
 *
 * ![](startWith.png)
 *
 * ## Examples
 *
 * Emit a value when a timer starts.
 *
 * ```ts
 * import { timer, map, startWith } from 'rxjs';
 *
 * timer(1000)
 *   .pipe(
 *     map(() => 'timer emit'),
 *     startWith('timer start')
 *   )
 *   .subscribe(x => console.log(x));
 *
 * // results:
 * // 'timer start'
 * // 'timer emit'
 * ```
 *
 * @param values Items you want the modified Observable to emit first.
 * @return A function that returns an Observable that synchronously emits
 * provided values before subscribing to the source Observable.
 *
 * @see {@link endWith}
 * @see {@link finalize}
 * @see {@link concat}
 */
export function startWith<t, D="">(...valores: D[]): OperatorFunction<t, T="" |="" D=""> {
  const scheduler = popScheduler(values);
  return operate((source, subscriber) => {
    // Here we can't pass `undefined` as a scheduler, because if we did, the
    // code inside of `concat` would be confused by the `undefined`, and treat it
    // like an invalid observable. So we have to split it two different ways.
    (scheduler ? concat(values, source, scheduler) : concat(values, source)).subscribe(subscriber);
  });
}
</t,></t,></t,></t,></t,></t,></t,></t></t,></t>