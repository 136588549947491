import { OperatorFunction } from '../types';
import { operate } from '../util/lift';
import { scanInternals } from './scanInternals';

export function scan<v, A="V">(acumulador: (acc: A | V, valor: V, índice: número) => A): OperadorFunción<v, V="" |="" A="">;
función de exportación scan<v, A="">(acumulador: (acc: A, valor: V, índice: número) => A, semilla: A): OperadorFunción<v, A="">;
función de exportación scan<v, A,="" S="">(acumulador: (acc: A | S, valor: V, índice: número) => A, semilla: S): OperadorFunción<v, A="">;

// TODO: enlace a una sección "redux pattern" en la guía (ubicación por determinar)

/**
 * Útil para encapsular y gestionar estado. Aplica un acumulador (o "función reductora")
 * a cada valor de la fuente después de un estado inicial se establece - ya sea a través de
 * un valor `seed` (segundo argumento), o desde el primer valor de la fuente.
 *
 * <span class="informal">Es como {@link reduce}, pero emite el actual
 * estado de acumulación después de cada actualización</span>
 *
 * ![](scan.png)
 *
 * This operator maintains an internal state and emits it after processing each value as follows:
 *
 * 1. First value arrives
 *   - If a `seed` value was supplied (as the second argument to `scan`), let `state = seed` and `value = firstValue`.
 *   - If NO `seed` value was supplied (no second argument), let `state = firstValue` and go to 3.
 * 2. Let `state = accumulator(state, value)`.
 *   - If an error is thrown by `accumulator`, notify the consumer of an error. The process ends.
 * 3. Emit `state`.
 * 4. Next value arrives, let `value = nextValue`, go to 2.
 *
 * ## Examples
 *
 * An average of previous numbers. This example shows how
 * not providing a `seed` can prime the stream with the
 * first value from the source.
 *
 * ```ts
 * import { of, scan, map } from 'rxjs';
 *
 * const numbers$ = of(1, 2, 3);
 *
 * numbers$
 *   .pipe(
 *     // Get the sum of the numbers coming in.
 *     scan((total, n) => total + n),
 *     // Get the average by dividing the sum by the total number
 *     // received so far (which is 1 more than the zero-based index).
 *     map((sum, index) => sum / (index + 1))
 *   )
 *   .subscribe(console.log);
 * ```
 *
 * The Fibonacci sequence. This example shows how you can use
 * a seed to prime accumulation process. Also... you know... Fibonacci.
 * So important to like, computers and stuff that its whiteboarded
 * in job interviews. Now you can show them the Rx version! (Please don't, haha)
 *
 * ```ts
 * import { interval, scan, map, startWith } from 'rxjs';
 *
 * const firstTwoFibs = [0, 1];
 * // An endless stream of Fibonacci numbers.
 * const fibonacci$ = interval(1000).pipe(
 *   // Scan to get the fibonacci numbers (after 0, 1)
 *   scan(([a, b]) => [b, a + b], firstTwoFibs),
 *   // Get the second number in the tuple, it's the one you calculated
 *   map(([, n]) => n),
 *   // Start with our first two digits :)
 *   startWith(...firstTwoFibs)
 * );
 *
 * fibonacci$.subscribe(console.log);
 * ```
 *
 * @see {@link expand}
 * @see {@link mergeScan}
 * @see {@link reduce}
 * @see {@link switchScan}
 *
 * @param accumulator A "reducer function". This will be called for each value after an initial state is
 * acquired.
 * @param seed The initial state. If this is not provided, the first value from the source will
 * be used as the initial state, and emitted without going through the accumulator. All subsequent values
 * will be processed by the accumulator function. If this is provided, all values will go through
 * the accumulator function.
 * @return A function that returns an Observable of the accumulated values.
 */
export function scan<v, A,="" S="">(acumulador: (acc: V | A | S, valor: V, índice: número) => A, ¿semilla?: S): OperadorFunción<v, V="" |="" A=""> {
  // providing a seed of `undefined` *should* be valid and trigger
  // hasSeed! so don't use `seed !== undefined` checks!
  // For this reason, we have to check it here at the original call site
  // otherwise inside Operator/Subscriber we won't know if `undefined`
  // means they didn't provide anything or if they literally provided `undefined`
  return operate(scanInternals(accumulator, seed as S, arguments.length >= 2, true));
}
</v,></v,></v,></v,></v,></v,></v,></v,>