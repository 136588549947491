import { Observable } from '../Observable';
import { EmptyError } from '../util/EmptyError';
import { OperatorFunction, TruthyTypesOf } from '../types';
import { filter } from './filter';
import { take } from './take';
import { defaultIfEmpty } from './defaultIfEmpty';
import { throwIfEmpty } from './throwIfEmpty';
import { identity } from '../util/identity';

export function first<t, D="T">(predicado?: null, defaultValor?: D): OperatorFunction<t, T="" |="" D="">;
función de exportación primero<t>(predicado: BooleanConstructor): OperadorFunción<t, TruthyTypesOf<T="">>;
función de exportación primero<t, D="">(predicado: BooleanConstructor, defaultValue: D): OperatorFunction<t, TruthyTypesOf<T=""> | D>;
función de exportación primero<t, S="" extends="" T="">(
  predicado: (valor: T, índice: número, fuente: Observable<t>) => el valor es S,
  defaultValue?: S
): OperadorFunción<t, S="">;
función de exportación primero<t, S="" extends="" T,="" D="">(
  predicado: (valor: T, índice: número, fuente: Observable<t>) => el valor es S,
  defaultValue: D
): OperadorFunción<t, S="" |="" D="">;
función de exportación primero<t, D="T">(
  predicado: (valor: T, índice: número, fuente: Observable<t>) => boolean,
  defaultValue?: D
): OperatorFunction<t, T="" |="" D="">;

/**
 * Emite sólo el primer valor (o el primer valor que cumple alguna condición)
 * emitido por el Observable fuente.
 *
 * <span class="informal">Emite sólo el primer valor. O emite sólo el primer
 * valor que pasa alguna prueba.</span>
 *
 * ![](first.png)
 *
 * If called with no arguments, `first` emits the first value of the source
 * Observable, then completes. If called with a `predicate` function, `first`
 * emits the first value of the source that matches the specified condition. Throws an error if
 * `defaultValue` was not provided and a matching element is not found.
 *
 * ## Examples
 *
 * Emit only the first click that happens on the DOM
 *
 * ```ts
 * import { fromEvent, first } from 'rxjs';
 *
 * const clicks = fromEvent(document, 'click');
 * const result = clicks.pipe(first());
 * result.subscribe(x => console.log(x));
 * ```
 *
 * Emits the first click that happens on a DIV
 *
 * ```ts
 * import { fromEvent, first } from 'rxjs';
 *
 * const div = document.createElement('div');
 * div.style.cssText = 'width: 200px; height: 200px; background: #09c;';
 * document.body.appendChild(div);
 *
 * const clicks = fromEvent(document, 'click');
 * const result = clicks.pipe(first(ev => (<htmlelement>ev.target).tagName === 'DIV'));
 * result.subscribe(x => console.log(x));
 * ```
 *
 * @see {@link filter}
 * @see {@link find}
 * @see {@link take}
 *
 * @throws {EmptyError} Delivers an EmptyError to the Observer's `error`
 * callback if the Observable completes before any `next` notification was sent.
 * This is how `first()` is different from {@link take}(1) which completes instead.
 *
 * @param {function(value: T, index: number, source: Observable<t>): boolean} [predicate]
 * An optional function called with each item to test for condition matching.
 * @param {D} [defaultValue] The default value emitted in case no valid value
 * was found on the source.
 * @return A function that returns an Observable that emits the first item that
 * matches the condition.
 */
export function first<t, D="">(
  predicado?: ((valor: T, índice: número, fuente: Observable<t>) => boolean) | null,
  defaultValue?: D
): OperatorFunction<t, T="" |="" D=""> {
  const hasDefaultValue = arguments.length >= 2;
  return (source: Observable<t>) =>
    source.pipe(
      predicado ? filtro((v, i) => predicado(v, i, fuente)) : identidad,
      take(1),
      hasDefaultValue ? defaultIfEmpty(defaultValue!) : throwIfEmpty(() => new EmptyError())
    );
}
</t></t,></t></t,></t></htmlelement></t,></t></t,></t,></t></t,></t,></t></t,></t,></t,></t,></t></t,></t,>