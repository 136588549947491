import { Observable } from '../Observable';
import { Subscriber } from '../Subscriber';
import { OperatorFunction } from '../types';
import { isFunction } from './isFunction';

/**
 * Used to determine if an object is an Observable with a lift function.
 */
export function hasLift(source: any): source is { lift: InstanceType<typeof Observable="">['lift'] } {
  return isFunction(source?.lift);
}

/**
 * Creates an `OperatorFunction`. Used to define operators throughout the library in a concise way.
 * @param init The logic to connect the liftedSource to the subscriber at the moment of subscription.
 */
export function operate<t, R="">(
  init: (liftedSource: Observable<t>, abonado: Abonado<r>) => (() => void) | void
): OperadorFunción<t, R=""> {
  return (source: Observable<t>) => {
    if (hasLift(source)) {
      return source.lift(function (this: Subscriber<r>liftedSource: Observable<t>) {
        try {
          return init(liftedSource, this);
        } catch (err) {
          this.error(err);
        }
      });
    }
    throw new TypeError('Unable to lift unknown Observable type');
  };
}
</t></r></t></t,></r></t></t,></typeof>