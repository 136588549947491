import { zip as zipStatic } from '../observable/zip';
import { ObservableInput, ObservableInputTuple, OperatorFunction, Cons } from '../types';
import { operate } from '../util/lift';

/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[]="">(otherInputs: [...ObservableInputTuple<a>]): OperatorFunction<t, Cons<T,="" A="">>;
/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[],="" R="">(
  otherInputsAndProject: [...ObservableInputTuple<a>],
  proyecto: (...valores: Cons<t, A="">) => R
): OperadorFunción<t, R="">;
/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[]="">(...otherInputs: [...ObservableInputTuple<a>]): OperatorFunction<t, Cons<T,="" A="">>;
/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[],="" R="">(
  ...otherInputsAndProject: [...ObservableInputTuple<a>(...valores: Cons<t, A="">) => R]
): OperadorFunción<t, R="">;

/**
 * @deprecated Replaced with {@link zipWith}. Will be removed in v8.
 */
export function zip<t, R="">(...fuentes: Array<observableinput<any> | ((...valores: Array<any>) => R)>): OperadorFunción<t, any=""> {
  return operate((fuente, abonado) => {
    zipStatic(fuente como ObservableInput<any>...(fuentes como matriz<observableinput<any>>)).subscribe(subscriber);
  });
}
</observableinput<any></any></t,></any></observableinput<any></t,></t,></t,></a></t,></t,></a></t,></t,></t,></a></t,></t,></a></t,>