import { ObservableInput, OperatorFunction, ObservedValueOf } from '../types';
import { map } from './map';
import { innerFrom } from '../observable/innerFrom';
import { operate } from '../util/lift';
import { mergeInternals } from './mergeInternals';
import { isFunction } from '../util/isFunction';

/* tslint:disable:max-line-length */
export function mergeMap<t, O="" extends="" ObservableInput<any="">>(
  proyecto: (valor: T, índice: número) => O,
  ¿concurrente?: número
): OperadorFunción<t, ObservedValueOf<O="">>;
/** @deprecated El parámetro `resultSelector` será eliminado en la v8. Utilice un `map` interno en su lugar. Detalles: https://rxjs.dev/deprecations/resultSelector */
función de exportación mergeMap<t, O="" extends="" ObservableInput<any="">>(
  proyecto: (valor: T, índice: número) => O,
  resultSelector: undefined,
  concurrente: número
): OperatorFunction<t, ObservedValueOf<O="">>;
/** @deprecated El parámetro `resultSelector` será eliminado en la v8. Utilice un `map` interno en su lugar. Detalles: https://rxjs.dev/deprecations/resultSelector */
función de exportación mergeMap<t, R,="" O="" extends="" ObservableInput<any="">>(
  proyecto: (valor: T, índice: número) => O,
  resultSelector: (outerValue: T, innerValue: ObservedValueOf<o>outerIndex: número, innerIndex: número) => R,
  concurrente: número
): OperatorFunction<t, R="">;
/* tslint:enable:max-line-length */

/**
 * Proyecta cada valor de origen a un Observable que se fusiona en la salida
 * Observable.
 *
 * <span class="informal">Mapea cada valor a un Observable, luego aplana todos los
 * estos Observables internos usando {@link mergeAll}.</span>
 *
 * ![](mergeMap.png)
 *
 * Returns an Observable that emits items based on applying a function that you
 * supply to each item emitted by the source Observable, where that function
 * returns an Observable, and then merging those resulting Observables and
 * emitting the results of this merger.
 *
 * ## Example
 *
 * Map and flatten each letter to an Observable ticking every 1 second
 *
 * ```ts
 * import { of, mergeMap, interval, map } from 'rxjs';
 *
 * const letters = of('a', 'b', 'c');
 * const result = letters.pipe(
 *   mergeMap(x => interval(1000).pipe(map(i => x + i)))
 * );
 *
 * result.subscribe(x => console.log(x));
 *
 * // Results in the following:
 * // a0
 * // b0
 * // c0
 * // a1
 * // b1
 * // c1
 * // continues to list a, b, c every second with respective ascending integers
 * ```
 *
 * @see {@link concatMap}
 * @see {@link exhaustMap}
 * @see {@link merge}
 * @see {@link mergeAll}
 * @see {@link mergeMapTo}
 * @see {@link mergeScan}
 * @see {@link switchMap}
 *
 * @param {function(value: T, ?index: number): ObservableInput} project A function
 * that, when applied to an item emitted by the source Observable, returns an
 * Observable.
 * @param {number} [concurrent=Infinity] Maximum number of input
 * Observables being subscribed to concurrently.
 * @return A function that returns an Observable that emits the result of
 * applying the projection function (and the optional deprecated
 * `resultSelector`) to each item emitted by the source Observable and merging
 * the results of the Observables obtained from this transformation.
 */
export function mergeMap<t, R,="" O="" extends="" ObservableInput<any="">>(
  proyecto: (valor: T, índice: número) => O,
  ¿resultadoSelector?: ((outerValue: T, innerValue: ObservedValueOf<o>, outerIndex: número, innerIndex: número) => R) | número,
  concurrente: número = Infinito
): OperadorFunción<t, ObservedValueOf<O=""> | R> {
  if (isFunction(resultSelector)) {
    // DEPRECATED PATH
    return mergeMap((a, i) => map((b: any, ii: number) => resultSelector(a, b, i, ii))(innerFrom(project(a, i))), concurrent);
  } else if (typeof resultSelector === 'number') {
    concurrent = resultSelector;
  }

  return operate((source, subscriber) => mergeInternals(source, subscriber, project, concurrent));
}
</t,></o></t,></t,></o></t,></t,></t,></t,></t,>