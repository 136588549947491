/**
 * Usado para crear subclases de Error hasta que la comunidad se aleje de ES5.
 *
 * Esto se debe a que la compilación de TypeScript a ES5 tiene problemas con la subclase Errors.
 * así como otros tipos incorporados: https://github.com/Microsoft/TypeScript/issues/12123
 *
 * @param createImpl Una función de fábrica para crear la implementación real del constructor. La función devuelta
 * debe ser una función con nombre que llame a `_super` internamente.
 */
export function crearClaseError<t>(createImpl: (_super: any) => any): T {
  const _super = (instance: any) => {
    Error.call(instance);
    instance.stack = new Error().stack;
  };

  const ctorFunc = createImpl(_super);
  ctorFunc.prototype = Object.create(Error.prototype);
  ctorFunc.prototype.constructor = ctorFunc;
  return ctorFunc;
}
</t>