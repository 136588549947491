import { identity } from './identity';
import { UnaryFunction } from '../types';

export function pipe(): typeof identity;
export function pipe<t, A="">(fn1: UnaryFunction<t, A="">): UnaryFunction<t, A="">;
función de exportación tubería<t, A,="" B="">(fn1: UnaryFunction<t, A="">fn2: UnaryFunction<a, B="">): UnaryFunction<t, B="">;
función de exportación tubería<t, A,="" B,="" C="">(fn1: UnaryFunction<t, A="">fn2: UnaryFunction<a, B="">fn3: UnaryFunction<b, C="">): UnaryFunction<t, C="">;
función de exportación tubería<t, A,="" B,="" C,="" D="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">
): UnaryFunction<t, D="">;
función de exportación tubería<t, A,="" B,="" C,="" D,="" E="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">,
  fn5: UnaryFunction<d, E="">
): UnaryFunction<t, E="">;
función de exportación tubería<t, A,="" B,="" C,="" D,="" E,="" F="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">,
  fn5: UnaryFunction<d, E="">,
  fn6: UnaryFunction<e, F="">
): UnaryFunction<t, F="">;
función de exportación tubería<t, A,="" B,="" C,="" D,="" E,="" F,="" G="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">,
  fn5: UnaryFunction<d, E="">,
  fn6: UnaryFunction<e, F="">,
  fn7: UnaryFunction<f, G="">
): UnaryFunction<t, G="">;
función de exportación tubería<t, A,="" B,="" C,="" D,="" E,="" F,="" G,="" H="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">,
  fn5: UnaryFunction<d, E="">,
  fn6: UnaryFunction<e, F="">,
  fn7: UnaryFunction<f, G="">,
  fn8: UnaryFunction<g, H="">
): UnaryFunction<t, H="">;
función de exportación tubería<t, A,="" B,="" C,="" D,="" E,="" F,="" G,="" H,="" I="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">,
  fn5: UnaryFunction<d, E="">,
  fn6: UnaryFunction<e, F="">,
  fn7: UnaryFunction<f, G="">,
  fn8: UnaryFunction<g, H="">,
  fn9: UnaryFunction<h, I="">
): UnaryFunction<t, I="">;
función de exportación tubería<t, A,="" B,="" C,="" D,="" E,="" F,="" G,="" H,="" I="">(
  fn1: UnaryFunction<t, A="">,
  fn2: UnaryFunction<a, B="">,
  fn3: UnaryFunction<b, C="">,
  fn4: UnaryFunction<c, D="">,
  fn5: UnaryFunction<d, E="">,
  fn6: UnaryFunction<e, F="">,
  fn7: UnaryFunction<f, G="">,
  fn8: UnaryFunction<g, H="">,
  fn9: UnaryFunction<h, I="">,
  ...fns: UnaryFunction<any, any="">[]
): UnaryFunction<t, unknown="">;

/**
 * pipe() puede ser llamada en una o más funciones, cada una de las cuales puede tomar un argumento ("UnaryFunction")
 * y lo utiliza para devolver un valor.
 * Devuelve una función que toma un argumento, lo pasa a la primera UnaryFunction, y luego
 * pasa el resultado a la siguiente, pasa ese resultado a la siguiente, y así sucesivamente.  
 */
export function pipe(...fns: Matriz<unaryfunction<any, any="">>): UnaryFunction<any, any=""> {
  return pipeFromArray(fns);
}

/** @internal */
export function pipeFromArray<t, R="">(fns: Matriz<unaryfunction<t, R="">>): UnaryFunction<t, R=""> {
  if (fns.length === 0) {
    return identidad como UnaryFunction<any, any="">;
  }

  if (fns.length === 1) {
    return fns[0];
  }

  return function piped(input: T): R {
    return fns.reduce((prev: any, fn: UnaryFunction<t, R="">) => fn(prev), input as any);
  };
}
</t,></any,></t,></unaryfunction<t,></t,></any,></unaryfunction<any,></t,></any,></h,></g,></f,></e,></d,></c,></b,></a,></t,></t,></t,></h,></g,></f,></e,></d,></c,></b,></a,></t,></t,></t,></g,></f,></e,></d,></c,></b,></a,></t,></t,></t,></f,></e,></d,></c,></b,></a,></t,></t,></t,></e,></d,></c,></b,></a,></t,></t,></t,></d,></c,></b,></a,></t,></t,></t,></c,></b,></a,></t,></t,></t,></b,></a,></t,></t,></t,></a,></t,></t,></t,></t,></t,>