import { ReadableStreamLike } from '../types';
import { isFunction } from './isFunction';

export async function* readableStreamLikeToAsyncGenerator<t>(readableStream: ReadableStreamLike<t>): AsyncGenerator<t> {
  const reader = readableStream.getReader();
  try {
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        return;
      }
      yield value!;
    }
  } finally {
    reader.releaseLock();
  }
}

export function isReadableStreamLike<t>(obj: any): obj es ReadableStreamLike<t> {
  // No queremos usar comprobaciones instanceof porque devolverían
  // false para instancias de otro Realm, como un <iframe>.
  return isFunction(obj?.getReader);
}
</iframe></t></t></t></t></t>