/**
 * Elimina un elemento de un array, mutándolo.
 * @param arr El array del que eliminar el elemento
 * @param item El elemento a eliminar
 */
exportar función arrRemove<t>(arr: T[] | undefined | null, item: T) {
  if (arr) {
    const index = arr.indexOf(item);
    0 <= index && arr.splice(index, 1);
  }
}
</t>